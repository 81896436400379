import $ from 'jquery';
import { caseInsensitiveMatch } from '../components/utils';

const $input = $('.js-confirm-input');
const $submit = $('[type="submit"]', $input.closest('form'));

$submit.each((_, element) => $(element).prop('disabled', true));

$input.on('input', event => {
  if (caseInsensitiveMatch(event.target.value, event.target.dataset.verify)) {
    $submit.prop('disabled', false);
  } else {
    $submit.prop('disabled', true);
  }
});
