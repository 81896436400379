import $ from 'jquery';

const enableSubmit = enabled => {
  $('[type="submit"').each((_, element) => {
    if (enabled) {
      element.removeAttribute('disabled');
    } else {
      element.setAttribute('disabled', true);
    }
  });
};

window.captchaSuccessCallback = () => enableSubmit(true);
window.captchaExpireCallback = () => enableSubmit(false);
